import { ScrollCaptain } from 'scroll-captain';
import './double-image-text-teaser.scss';

class DoubleImageTextTeaser {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-doubleimagetextteaser',
            itemValue: 'item',
            imageValue: 'image',
            contentValue: 'content',
            breakpoint: 768
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$root = element;
        this.$$items = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.itemValue}"]`);

        // Options
        this.animations = [];
        this.breakpoint = window.matchMedia(`(min-width: ${this.settings.breakpoint}px)`);
        this.breakpointActive = this.breakpoint.matches;
    }

    initialize () {
        this.loadAnimation();
        this.setEvents();
    }

    loadAnimation () {    
        this.$$items.forEach(($item, index) => {
            this.animations[`${index}-1`] = new ScrollCaptain($item, {
                initAttr: this.settings.initAttr,
                animateImage: {
                    targetSuffix: this.settings.imageValue,
                    onscroll: true,
                    translateY: this.breakpoint.matches && !window.reducedMotionActive ? { 0: 200, 40: 50, 60: -50, 100: -200 } : null
                },
                animateContentBlock: {
                    targetSuffix: this.settings.contentValue,
                    onscroll: true,
                    translateY: this.breakpoint.matches && !window.reducedMotionActive ? { 0: 100, 40: 50, 60: -50, 100: -100 } : null
                },
                updateOnResize: () => {
                    if (this.breakpoint.matches !== this.breakpointActive) {
                        this.breakpointActive = this.breakpoint.matches;
                        this.updateAnimation(false);
                    }
                }
            });

            this.animations[`${index}-2`] = new ScrollCaptain($item, {
                initAttr: this.settings.initAttr,
                bottom: '-50%',
                animateContent: {
                    targetSuffix: this.settings.contentValue,
                    class: 'double-image-text-teaser__item-content--expanded',
                    resetOnScrollDown: false
                },
            });
        });    
    }

    updateAnimation (updateRequired) {
        this.$$items.forEach(($item, index) => {
            this.animations[`${index}-1`].defaultOptions.animateImage.translateY = this.breakpoint.matches && !window.reducedMotionActive ? { 0: 200, 40: 50, 60: -50, 100: -200 } : null;
            this.animations[`${index}-1`].defaultOptions.animateContentBlock.translateY = this.breakpoint.matches && !window.reducedMotionActive ? { 0: 100, 40: 50, 60: -50, 100: -100 } : null;

            if (updateRequired) {
                this.animations[`${index}-1`].update();
            }
        }); 
    }

    setEvents () {
        window.addEventListener('motionPreferenceChanged', () => {
            this.updateAnimation(true);
        });
    }
}

export { DoubleImageTextTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-doubleimagetextteaser="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new DoubleImageTextTeaser($root);
            $rootAPI.initialize();
        });
    }
});
